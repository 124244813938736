<template>
    <div class="container"> 
      <div class="col-md-8 offset-md-2 bg-cadt pd-1">
        <div class="row banner">
          <img src="../public/images/cadt_white.png" class="col-md-12"> 
        <h2 class="text-khmer text-cadt text-danger col-md-12">TRACKING OF {{service_title}}</h2>
        </div>  
          <div class="row" v-if="rst">
            <table class="table text-white">
              <tr>
                <th>No</th>
                <th>Title</th>
                <!-- <th>Approve By</th> -->
                <th>Estimate Process</th>
                <th>Status</th>
                <th>Approved At</th>
                <th>Comment</th>
              </tr> 
            
            <div :set="i=0"></div>
            <tr v-for="res in results"  :key="res.id" :set="i=i+res.number_day_process"> 
              <td>{{res.step}}</td>
              <td>{{res.title_km}}</td>
              <!-- <td>{{res.user_role.title_km}}</td> -->
              <td>{{currentDate(res.created_at,i)}}</td>
              <td>{{res.status}}</td>
              <td>{{res.approved_at}}</td>
              <td>{{res.comment}}</td>
            </tr>
            </table>

            <div class="col-md-12 float-right">
              <h4 class="text-white">REQUEST ID: <b>{{requestId}}</b></h4>
              <router-link :to="{name:'home'}" class="bg-idt-1 dispaly-item-center fadeIn pd-1 rounded-0 text-white btn-close"> Close</router-link>
            </div> 
                  
          </div> 
          <div class="row" v-if="!rst">
            <div class="col-md-12 float-right">
              <h1 class="text-white">ក្រុមការងារនឹងទាក់ទងដោយផ្ទាល់ក្នុងពេលឆាប់ៗនេះ។ <br> អរគុណ!!!</h1>
              <h4 class="text-white">REQUEST ID: <b>{{requestId}}</b></h4>
              <router-link :to="{name:'home'}" class="float-left text-right btn bg-back text-white h5 btn-close"> Close</router-link>
            </div> 
          </div>
        
      </div>
      </div>
</template>

<script>
import { ref } from "vue"; 
import getTrackingService from "../composable/getTrackingService";

//  console.log('ref::',ref)
export default {
  name: "HelloWorld",
  param: ["id", "serviceID"],
  components: {},
  methods: {
    currentDate(dt,day=0) {
      const current = new Date(dt);
      current.setDate(current.getDate()+day);
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      return date;
    }
  },
  data() {
    let results = ref("");
    let serviceID = ref("");
    let requestId = ref("");
    let service_title = ref("");
    let rst = ref(true);
    let getService = async () => {
      const data = await getTrackingService(this.$route.params.id);
      results.value = data.result.trackingServices; 
      requestId.value = data.result.requestId;
      service_title.value = data.result.service_title;
      console.log("str:",data.result);
      if(data.result.trackingServices != ''){
        rst.value = true;
      }else{
        rst.value = false;
      }
    }; 
    getService(); 
    return { results, serviceID,requestId,rst,service_title};
  }
};
</script>
<style scoped>
body {
  display: grid;
  align-items: center;
  width: 100%;
  font-family:Niradei,sans-serif;
}
ul {
  list-style-type: decimal;
}
ul.answer {
  list-style-type: disclosure-closed;
}
.banner{
  position: sticky;
  top: 0;
  background: #14294b;
  z-index: 2; 
  padding: 10px;
  box-shadow: 0px 7px 10px rgb(0 0 0 / 12%);
}
.btn-close{
  width: 100px;
    float: right;
    position: absolute;
    right: 48px;
    top: -11px;
}
</style>