<template>
  <StudentService></StudentService>
</template>

<script>
// @ is an alias to /src
import StudentService from '@/components/studentConfirm.vue'
export default {
  name: 'HomeView',
  components: {
    StudentService,
  } 
}
</script>
