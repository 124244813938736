// import { ref } from "vue"; 
import config from '../../config'
const getStaffs = async (id) => {
    let staff = {}; 
    const axios = require("axios"); 
      try { 
        staff = await axios.get(config.urlAPI + "/api/seach_staff?staff_id=" + id)
         .then(res =>res.data.staff[0]);
        
      } catch (err) { 
        // error.value = err.message; 
      }   
    return {staff}
}
export default getStaffs