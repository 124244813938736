import { createRouter, createWebHistory } from 'vue-router'
import authStudent from '../middleware/authStudent';
import authStaff from '../middleware/authStaff';
import ClassFAuth from '../middleware/ClassFAuth';
import auth from '../middleware/auth';
import log from '../middleware/log';
import HomeView from '../views/HomeView.vue'
import StudentPage from '../views/StudentPage.vue'
import FacultyPage from '../views/FacultyPage.vue'
import ClassFacilitatorPage from '../views/ClassFacilitatorPage.vue'
import MakeRequest from '../views/MakeRequest.vue'
import TrackingProcess from '../views/TrackingProcess.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue' 
import studentConfirm from '../views/verify.vue' 
import claffFlogin from '../views/cfLogin.vue' 
import welcomePage from '../views/WelcomePage.vue' 
import infoPage from '../views/InfoPage.vue' 
import statffConfirm from '../views/staffVerify.vue' 
const router = createRouter({
  history: createWebHistory(process.env.baseURL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
                middleware: log,
          },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        hideNavbar: true,
      }
    },
    {
      path: '/exit',
      name: 'exit',
      beforeEnter: () => {
        localStorage.removeItem('tokenStudent');
        localStorage.removeItem('tokenStaff');
        localStorage.removeItem('classFAuth');
      window.location.href = "/"
      },
      meta: {
        hideNavbar: true,
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta:{
        middleware: [log],
        hideNavbar: true,
      },
    },
    {
      path: '/student',
      name: 'student', 
      component: StudentPage,
      meta:{
        middleware: [authStudent],
        hideNavbar: true,
        transition: 'slide-left' 
      },
    },
    {
      path: '/verify',
      name: 'verify',
      component: studentConfirm,
      meta:{
        hideNavbar: true,
        // middleware: [authStudent],
        transition: 'slide-left' 
      },
    },
    {
      path: '/info',
      name: 'info',
      component: infoPage,
      meta:{
        hideNavbar: true, 
        transition: 'slide-left' 
      },
    }, {
      path: '/welcome',
      name: 'welcome',
      component: welcomePage,
      meta:{
        hideNavbar: true, 
        transition: 'slide-left' 
      },
    }, 
    {
      path: '/confirmation',
      name: 'confirmation',
      component: statffConfirm,
      meta:{
        hideNavbar: true,
        // middleware: [authStudent],
        transition: 'slide-left' 
      },
    },
    {
      path: '/cf_login',
      name: 'cf_login',
      component: claffFlogin,
      meta:{
        hideNavbar: true,
        // middleware: [authStudent],
        transition: 'slide-left' 
      },
    },
    {
      path: '/faculty',
      name: 'faculty',
      component: FacultyPage,
      meta:{

        middleware: [authStaff],
        hideNavbar: true,
      },
    },
    {
      path: '/class_facilitator',
      name: 'ClassFacilitator',
      component: ClassFacilitatorPage,
      meta:{
        middleware: [ClassFAuth],
        hideNavbar: true,
      },
    },
    {
      path: '/makeRequest/:id',
      name: 'makeRequest',
      props:true,
      component: MakeRequest,
      meta:{
        middleware: [log],
        hideNavbar: true,
      },
    },
    {
      path: '/trackingProcess/:id',
      name: 'trackingProcess',
      props:true,
      component: TrackingProcess,
      meta:{
        middleware: [log],
        hideNavbar: true,
      },
    },
    {
      path: '/track',
      name: 'tracking',
      component: Register,
      meta:{
        middleware: [log],
        hideNavbar: true, 
      },
    },
    {
      path: '/about',
      name: 'about', 
      component: () => import('../views/AboutView.vue'),
      meta:{
        middleware: [auth, log],
      },
    }
  ]
})
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
   const subsequentMiddleware = middleware[index];
   // If no subsequent Middleware exists,
   // the default `next()` callback is returned.
   if (!subsequentMiddleware) return context.next;
 
   return (...parameters) => {
     // Run the default Vue Router `next()` callback first.
     context.next(...parameters);
     // Then run the subsequent Middleware with a new
     // `nextMiddleware()` callback.
     const nextMiddleware = nextFactory(context, middleware, index +1);
     subsequentMiddleware({ ...context, next: nextMiddleware });
   };
 }
 
 router.beforeEach((to, from, next) => {
   if (to.meta.middleware) {
     const middleware = Array.isArray(to.meta.middleware)
       ? to.meta.middleware
       : [to.meta.middleware];
 
     const context = {
       from,
       next,
       router,
       to,
     };
     const nextMiddleware = nextFactory(context, middleware, 1);
 
     return middleware[0]({ ...context, next: nextMiddleware });
   }
   return next();
 });
 export default router;