<template> 
  <div class="container">
    <img src="../public/images/cadt_logo.png" alt="" class="col-md-6 padding-0">
    <h1 class="text-cadt text-danger">CLASS Facilitator</h1>
    <div class="row" v-if="isLogin">
      <!-- <a href="/exit" class="col-md-6 m-top-1"><div class="bg-idt-primary dispaly-item-center fadeIn pd-1 rounded-0 bg-cadt-hover-danger"><i class="fas fa-arrow-left text-white font-5rem"></i><h1 class="text-center text-cadt text-white h1">EXIT</h1></div></a> -->
      <router-link v-for="sv in services" :key="sv.id"
       :to="{name:'makeRequest', params: {id: sv.id}}"    class="col-md-6 m-top-1">
        <div class="bg-idt dispaly-item-center fadeIn  pd-1 rounded-0 bg-cadt-hover-danger">
          <i :class="sv.icon" class=" text-white font-5rem"></i>
          <h1 class="text-center text-cadt text-white h1">{{sv.title_en}}</h1>
        </div>    
      </router-link>  
    </div>

    <div class="row" v-if="!isLogin">
      <!-- <a href="/exit" class="col-md-6 m-top-1"><div class="bg-idt-primary dispaly-item-center fadeIn pd-1 rounded-0 bg-cadt-hover-danger"><i class="fas fa-arrow-left text-white font-5rem"></i><h1 class="text-center text-cadt text-white h1">EXIT</h1></div></a> -->
      <a href="/exit" v-for="sv in services" :key="sv.id"
           class="col-md-6 m-top-1">
        <div class="bg-idt dispaly-item-center fadeIn  pd-1 rounded-0 bg-cadt-hover-danger">
          <i :class="sv.icon" class=" text-white font-5rem"></i>
          <h1 class="text-center text-cadt text-white h1">{{sv.title_en}}</h1>
        </div>    
      </a>  
    </div>
  </div>
</template>

<script> 
import { ref} from 'vue'; 
import getServies from '../composable/getServies' 
let isLogin = localStorage.getItem("classFAuth")?true:false;
export default {
  name: 'HelloWorld',
  components:{ 
  },
   setup() {  
   let services = ref('');  
   let getService = async ()=>{ 
   const data=  await getServies(3);  
   console.log("data.services:",data.services)
   services.value = data.services 
   } 
   getService();
    console.log("services:",services)
    return {services,isLogin}; 
  }, 
}
</script>
<style scoped>
body{
  display: grid;
  align-items: center;
  width: 100%;
} 
</style>