
<template> 
  <RequestForm></RequestForm>
 </template>
 
 <script> 
 import RequestForm from '../components/RequestForm.vue'
 export default{
   components:{RequestForm},
   name,
 }
 </script>
 
