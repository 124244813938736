<style>
  @import '../public/assets/css/bootstrap.min.css';
  @import '../public/assets/css/atlantis.css';
  @import '../public/assets/css/demo.css'; 
</style>
<script setup>
import { RouterView } from 'vue-router';
// import Navbar from '@/components/Navbar.vue';

</script>

<template>
  <div>
    <!-- <Navbar  v-if="!$route.meta.hideNavbar" /> -->
  </div>
  <RouterView /> 
</template>
<script>
export default {
  name}
</script>