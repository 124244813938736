<template>
  <Info></Info>
</template>

<script>
// @ is an alias to /src
import Info from '@/components/Info.vue'
export default {
  name: 'welcome-page',
  components: {
    Info,
  } 
}
</script>
