<template>
  <FaculyService></FaculyService>
</template>

<script>
// @ is an alias to /src
import FaculyService from '@/components/FacultyService.vue'
export default {
  name: 'HomeView',
  components: {
    FaculyService,
  } 
}
</script>
