// import { ref } from "vue"; 
import config from '../../config'
const trackingService = async (id) => {
    let result = {}; 
    const axios = require("axios"); 
   
      try { 
        result = await axios.get(config.urlAPI+'/api/tracking_service?request_id='+id)
         .then(res =>res.data);
        
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {result}
}
export default trackingService