<template>   
   
  <div class="bg-info-main" v-if="!confirmStudent">
    <form @submit.prevent="submit" id="form_search">
    <div class="get-student-info col-md-10 pd-1 bg-idt text-center" style="    height: 50vh;">
      <h1 class="text-cadt text-info text-center">CADT</h1>
      <h1 class="text-white">WELCOME TO CADT</h1>
      <div class="form-group"> 
        <input type="text" id="user_id" autocomplete="off"  style="background: transparent;
        border: transparent;color:transparent;" class="form" placeholder="" maxlength="10" required v-model="studentId" autofocus>
      </div> 
    </div>
  </form>

  </div>
  <div class="bg-info-main" v-if="confirmStudent">
    <div class="container h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="card1">
          <div class="row">
           
          <div class="col-md-6 text-center bg-white">
              <br>
              <h1 class="text-cadt text-danger">Welcome to CADT</h1>
              <div class="d-lfex justify-content-center flex-column">
                <div class="name_container">
                  <div class="name h1">{{student.first_name_en}} {{student.last_name_en}}</div>
                </div>
                <div class="address">{{student.first_name_km}} {{student.last_name_km}}</div>
              </div>
              <div>
                <h4 class="btn btn-sm btn-warning row">G{{student.generation_id}} -{{student.major.title_en}}</h4>
                <hr>
              </div>
              <div class="follow">
              
              <div class="info_container">
                <div class="info">
                 
                  <p>Date of Birth</p>
                  <p>{{student.dob}}</p>								
                </div>
                <div class="info">
                  <p>Phone</p>
                  <p>0{{student.phone1}}</p>							
                </div>
                <div class="info">
                  <p>Email</p>
                  <p>{{student.email}}</p>						
                </div>			
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <img :src="'http://udms.cadt.edu.kh'+student.image_profile">
          </div>
          
          <div class="card-footer col-md-12">
            <div class="view_profile">Arrvivale Time:</div>
            <div class="message">
              {{currentTime}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div v-if="!check_key" class="container">
  <form @submit.prevent="checkKey" class="row text-center">
    <input type="text" id="user_id" autocomplete="off"  class="form-controll text-center" placeholder="" required v-model="WelcomeKey" autofocus>
  </form>
</div>
  
</template>

<script> 
import { ref} from 'vue';  
import getUser from '../composable/getUser'   
import getStudent from '@/composable/getStudent'; 
export default {
  name: 'HelloWorld',
   setup() {  
    const studentId = ref('');
    const WelcomeKey = ref(''); 
    const confirmStudent = ref('');  
    confirmStudent.value = false;
    const student1 = ref(''); 
    let student = ref(''); 
    let currentTime = ref('');    
    let check_key = ref('');    
    check_key.value = false;
   
   const correct = ()=>{   
    window.location.href = "/welcome"
   }
   if(localStorage.getItem("WelcomeKey")){
    check_key.value = true;
   }

   const checkKey = ()=>{
    if(WelcomeKey.value == "Cadt@2K2Z!@#"){
      localStorage.setItem("WelcomeKey",true);  
      check_key.value = true; 
    }
    console.log("check_key:",check_key.value)
   }
    const submit = async ()=>{
      let data= await getUser(studentId.value);  
      if(data.result ==null){
        console.log("not found.")
      }else{ 
        if(data.result.user_type==2){
          let studentData= await getStudent(data.result.user_id);  
              student.value = studentData.student; 
              confirmStudent.value = true;
              let d = new Date();
              currentTime.value = d.toLocaleString();
              // console.log("DFS:",studentData.student);
              setTimeout(() => {
            confirmStudent.value = false;
            correct();
            }, 2000);
 
        }
      }
    } 
    return {student1,confirmStudent,studentId,WelcomeKey,student,submit,correct,currentTime,checkKey,check_key}; 
  },
  data() {
    return {
      services:[{title:'STUDENT ID', id:1,bgColor:'bg-idt',icon:'fas fa-id-card',route:'student'},
                {title:'TRANSCRIPT', id:2,bgColor:'bg-cadt',icon:'fas fa-table',route:'faculty'},
                {title:'TEMPORY CERTIFICATE', id:2,bgColor:'bg-idt-primary',icon:'fas fa-file-invoice',route:'track'},
                {title:'CERTIFICATE', id:2,bgColor:'bg-idt-primary',icon:'fas fa-id-card-alt',route:'track'},{title:'PERMISSION', id:2,bgColor:'bg-idt-primary',icon:'fas fa-file-signature',route:'track'},{title:'OTHER REQUEST', id:2,bgColor:'bg-idt-primary',icon:'fas fa-folder',route:'track'},
    ]
    }
  },
}
</script>
<style scoped>
body{
  display: grid;
  align-items: center;
  width: 100%;
} 
.main-info{
  position:absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto; 
  top: 50%; 
  width:80%;
  transform: translateY(-50%);
  background-color: rgb(239, 100, 0);
  z-index: 3;
}

.get-student-info{ 
  position:absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto; 
  top: 50%; 
  transform: translateY(-50%);
}
.bg-info-main{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height:100vh;
  z-index: 3;
  background: #444444d9;
}
.card-header{
  background-image: url('http://www.latestseotutorial.com/wp-content/uploads/2016/12/love-dp-for-facebook.jpg') !important;
  padding: 0 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align:center;
}
.card1{
  overflow: hidden;
  border:0 !important;
  width: 80%;
  box-shadow: 0px 0px 15px 1px;
  -webkit-box-shadow:0px 0px 15px 1px;
}
.profile_pic{
  position: absolute;
  bottom: -50px;
  height: 112px;
  width: 112px;
  padding: 5px;
  border: 2px solid #f39c12;
  border-radius: 50%;
}
.card-body{
  padding-top: 55px !important;
}
.profile_pic img{ 
  width: 100px;
  border-radius: 50%;
}
.name_container{
  display: flex;
  justify-content: center;

}
.name{
  font-size: 20px;
  font-weight: 700;
  color: gray;
  position: relative;
}
.address{
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: gray;
}
.follow{ 
  padding:0px !important;
  display: flex;
  justify-content: center;
}
.follow_btn{
  background: #2196F3;
  padding: 7px;
  color: #fff;
  border-radius: 12px;
  cursor: pointer;
}
.info_container{  
  display:block !important;
}
.info{
  display: flex;
  flex-direction: column;
}
.info p:first-child{
  margin-bottom: 0;
  font-size: 12px; 
  color: gray;
  text-transform: uppercase;
  text-align: center;
}
.info p:last-child{
  margin-bottom: 0;  
  color: rgb(221, 147, 0); 
  text-align: center;
}
.card-footer{
  padding: 0 !important;
  background: #fff !important;
  display: flex;
  border-top: 0 !important;
}

.message{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50%;
  text-transform: uppercase;
  background: #f39c12;
  color: #fff;
  cursor: pointer;
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.view_profile{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50%;
  text-transform: uppercase;
  background: #e74c3c;
  color: #fff;
  cursor: pointer;
  border-bottom-left-radius: calc(0.25rem - 1px);
  
}
</style>