// import { ref } from "vue"; 
import config from '../../config'
const getUser = async (id) => {
    let result = {}; 
    const axios = require("axios"); 
      try { 
        result = await axios.get(config.urlAPI + "/api/getUser?id=" + id)
         .then(res =>res.data.result[0]); 
      } catch (err) { 
        // error.value = err.message; 
        //  console.log("Erro:",err.message);
      }  
    return {result}
}
export default getUser