<template>
  <div class="bg-info-main" v-if="loadingReq">
    <div class="get-staff-info">
      <img src="../public/images/send-email.gif">

    </div>
  </div>
  <div class="container">
    <div class="col-md-8 offset-md-2 bg-form pd-01">
      <div class="row banner">
        <img src="../public/images/cadt_white.png" class="col-md-12">
        <h1 class="text-white col-md-12 q-title">{{ serviceTitle }}</h1>
      </div>
      <form @submit.prevent="getFormValues">
        <div class="row">
          <div class="col-md-12">
            <div class="question">
              <div v-for="sv in services" :key="sv.id" class="col-md-12">
                <div class="q-list" v-if="sv.question_group == 1">
                  <h1 class="text-left h3 text-white label-h">{{ sv.text }}</h1>
                  <p>{{ sv.description }}</p>
                </div>
                <div class="q-list" v-if="sv.question_group == 2">
                  <h1 class="text-left h3">{{ sv.text }}</h1>
                  <p>{{ sv.description }}</p>
                  <div v-if="sv.question_group == 2">
                    <ul class="answer">
                      <li v-for="an in sv.answers" :key="an.id">
                        <div v-if="an.correct_answer != 0">
                          <div v-if="sv.question_type == 1">
                            <input type="text" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                          </div>
                          <div v-if="sv.question_type == 2">
                            <textarea class="form-control" width="100%" :id="+sv.id"
                              :required="sv.is_require ? true : false"></textarea>
                          </div>
                          <div v-if="sv.question_type == 3">
                            <input type="checkbox" :value="an.id" :id="sv.id + '_' + an.id">
                            &nbsp;
                            <label :for="sv.id + '_' + an.id">{{ an.text }}</label>
                          </div>
                          <div v-if="sv.question_type == 4">
                            <input type="radio" :name="sv.id" :value="an.id" :id="sv.id + '_' + an.id">
                            &nbsp;
                            <label :for="sv.id + '_' + an.id">{{ an.text }}</label>
                          </div>
                          <div v-if="sv.question_type == 6">
                            <input type="date" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                          </div>
                          <div v-if="sv.question_type == 7">
                            <input type="number" :id="+sv.id" class="form-control"
                              :required="sv.is_require ? true : false">
                          </div>
                          <div v-if="sv.question_type == 8">
                            <input type="time" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                          </div>
                          <div v-if="sv.question_type == 9">
                            <input type="datetime-local" :id="+sv.id" class="form-control"
                              :required="sv.is_require ? true : false">
                          </div>
                          <div v-if="sv.question_type == 10">
                            <input type="file" class="form-control" :id="+sv.id" :required="sv.is_require ? true : false"
                              @change="handleFileUpload">
                          </div>
                        </div>
                      </li>
                      <li v-if="sv.answers.length == 0">
                        <div v-if="sv.question_type == 1">
                          <input type="text" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                        </div>
                        <div v-if="sv.question_type == 2">
                          <textarea class="form-control" width="100%" :id="+sv.id"
                            :required="sv.is_require ? true : false"></textarea>
                        </div>

                        <div v-if="sv.question_type == 6">
                          <input type="date" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                        </div>
                        <div v-if="sv.question_type == 7">
                          <input type="number" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                        </div>
                        <div v-if="sv.question_type == 8">
                          <input type="time" :id="+sv.id" class="form-control" :required="sv.is_require ? true : false">
                        </div>
                        <div v-if="sv.question_type == 9">
                          <input type="datetime-local" :id="+sv.id" class="form-control"
                            :required="sv.is_require ? true : false">
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-6 col-xs-6">
              <router-link :to="{name:'faculty'}" class="btn text-cadt text-left btn-danger"> BACK</router-link>
            </div> -->
          <div class="col-sm-12 col-xs-12 text-right">
            <a @click="$router.go(-1)" class="float-left text-right btn bg-back text-white h5">Cancel

            </a>

            <button type="submit" class="float-right text-right  btn bg-submit text-white h5">Submit</button>
          </div>

        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import getServiesQuestion from "../composable/getServiesQuestion";
import makeRequestPost from "../composable/makeRequestPost";
//  console.log('ref::',ref)
export default {
  name: "HelloWorld",
  param: ["id", "serviceID"],
  components: {},
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },

    getFormValues(submitEvent) {
      this.loadingReq = true;
      let file_key = null;
      let elements = submitEvent.target.elements;
      let id = localStorage.getItem("tokenStaff")
        ? localStorage.getItem("tokenStaff")
        : localStorage.getItem("tokenStudent")
          ? localStorage.getItem("tokenStudent")
          : localStorage.getItem("classFAuth");
      let str = '{';
      for (let i = 0; i < elements.length; i++) {
        if (
          elements[i].type.includes("checkbox") ||
          elements[i].type.includes("radio")
        ) {
          if (elements[i].checked) {
            str =
              str +
              '"' +
              elements[i].id +
              '"' +
              ":" +
              '"' +
              elements[i].value +
              '"' +
              ",";
          }
        } else if (elements[i].type.includes("file")) {
          file_key = elements[i].id;
          str = str + '"' + elements[i].id + '"' + ":" + '"file"' + ",";
        } else {
          if (elements[i].value) {
            str =
              str +
              '"' +
              elements[i].id +
              '"' +
              ":" +
              '"' +
              elements[i].value +
              '"' +
              ",";
          }
        }
      }
      str =
        str +
        '}';
      str = str
        .replace(",}", "}")
        .replace(/\n/g, "\\\\n")
        .replace(/\r/g, "\\\\r")
        .replace(/\t/g, "\\\\t");
      console.log("str:", str);
      let makeReq = async () => {
        const formData = new FormData();
        formData.append("file", this.selectedFile);
        formData.append("file_key", file_key);
        formData.append("string", str);
        formData.append("service", this.$route.params.id);
        formData.append("request_by", id);
        console.log("formData:", formData);
        const data = await makeRequestPost(formData);
        console.log("data:", data.request.success);
        if (data.request.success) {
          this.$router.push("/trackingProcess/" + data.request.request_id);
        }
      };
      makeReq();
    }
  },
  data() {
    let services = ref("");
    let serviceID = ref("");
    let serviceTitle = ref("");
    let loadingReq = false;
    let getService = async () => {
      const data = await getServiesQuestion(this.$route.params.id);
      services.value = data.result.questionnaire;
      serviceTitle.value = data.result.service;
      serviceID.value = this.$route.params.id;
    };
    getService();
    return { services, serviceID, serviceTitle, loadingReq };
  }
};
</script>
<style scoped>
body {
  display: grid;
  align-items: center;
  width: 100%;
  font-family: Niradei, sans-serif;
}

ul {
  list-style-type: decimal;
}

ul.answer {
  list-style-type: none !important;
}

.banner {
  position: sticky;
  top: 0;
  background: #14294b;
  z-index: 2;
  padding: 10px;
  box-shadow: 0px 7px 10px rgb(0 0 0 / 12%);
}

.q-title,
.question {}

.label-h {
  position: relative;
  text-decoration: underline;
}

.question .q-list {
  border: 1px solid gray;
  margin: 8px;
  border-radius: 3px;
  padding: 10px 10px;
}

.get-staff-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.bg-submit {
  background: linear-gradient(-45deg, rgba(0, 255, 81, 0) 5%, #14294b 5%);
  border: none;
}

.bg-back {
  background: linear-gradient(45deg, rgba(0, 255, 81, 0) 5%, #ff0000 5%);
  border: none;
}
</style>