// import { ref } from "vue"; 
import config from '../../config'
const getServies = async (id) => {
    let result = {};  
    const axios = require("axios"); 
      try { 
        result = await axios.get(config.urlAPI + "/api/request_service?service=" + id)
         .then(res =>res.data);
        
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {result}
}
export default getServies