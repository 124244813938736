// import { ref } from "vue"; 
import config from '../../config'
const getServies = async (id) => {
    let services = {}; 
    const axios = require("axios"); 
      try { 
        services = await axios.get(config.urlAPI + "/api/services?type=" + id)
         .then(res =>res.data.services);
        
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {services}
}
export default getServies