<template> 
  <TrackingService></TrackingService>
 </template>
 
 <script> 
 import TrackingService from '../components/TrackingService.vue'
 export default{
   components:{TrackingService},
   name,
 }
 </script>