<template> 
  <div class="container">
    <img src="../public/images/cadt_logo.png" alt="" class="logo padding-0">
    <h1 class="text-cadt text-danger">Welcome To our servie</h1>
    <div class="wrapper row text-center">
      <router-link :to="{name:sv.route,params:{id:'hello'}}" v-for="sv in services" :key="sv.id" :class="sv.bgColor"  class="dispaly-item-center fadeIn col-md ml-1 rounded-0 bg-cadt-hover-danger">
        <div>
          <i :class="sv.icon" class=" text-white font-5rem"></i>
          <h1 class="text-center text-cadt text-white h1">{{sv.title}}</h1>
        </div>    
      </router-link>
    </div>
  </div>
</template>

<script> 
export default {
  name: 'HelloWorld',
  components:{ 
  },
  data() {
    return {
      services:[
        {title:'STUDENTS', id:1,bgColor:'bg-idt',icon:'fas fa-user-graduate',route:'student'},
        {title:'Faculty', id:2,bgColor:'bg-cadt',icon:'fas fa-users',route:'faculty'},
        {title:'Class Facilitator', id:2,bgColor:'bg-idt-info',icon:'fas fa-school',route:'ClassFacilitator'},
        {title:'TRACKING', id:2,bgColor:'bg-idt-primary',icon:'fas fa-search',route:'tracking'},
    ] 
    }
  },
}
</script>
<style scoped>
body{
  display: grid;
  align-items: center;
}
</style>