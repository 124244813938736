<template>
  <ClassFacilitator></ClassFacilitator>
</template>

<script>
// @ is an alias to /src
import ClassFacilitator from '@/components/ClassFacilitator.vue'
export default {
  name: 'ClassFacilitatorPage',
  components: {
    ClassFacilitator,
  } 
}
</script>
