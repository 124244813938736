// import { ref } from "vue"; 
import config from '../../config'
const getStudents = async (id) => {
    let student = {}; 
    const axios = require("axios"); 
      try { 
        student = await axios.get(config.urlAPI + "/api/seach_student?student_id=" + id)
         .then(res =>res.data.student[0]);
        
      } catch (err) { 
        // error.value = err.message; 
      }  
    return {student}
}
export default getStudents