<template>
  <StaffConfirm></StaffConfirm>
</template>

<script>
// @ is an alias to /src
import StaffConfirm from '@/components/staffConfirm.vue'
export default {
  name: 'HomeView',
  components: {
    StaffConfirm,
  } 
}
</script>
