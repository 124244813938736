<template>
  <Welcome></Welcome>
</template>

<script>
// @ is an alias to /src
import Welcome from '@/components/Welcome.vue'
export default {
  name: 'welcome-page',
  components: {
    Welcome,
  } 
}
</script>
