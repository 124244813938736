<template>  
 <img src="../public/images/bg_img.jpg" class="bg_cover">
  <div class="bg-info-main" v-if="!confirmStudent">
    <form @submit.prevent="submit">
    <div class="get-student-info col-md-3 pd-1 bg-idt">
      <h4 class="text-cadt text-white">PLEASE ENTER YOUR STUDENT ID</h4>
      <div class="form-group">
        <label for="id" class="text-danger text-cadt">STUDENT ID</label>
        <input type="text" class="form-control" placeholder="IDT0000000000" maxlength="10" required v-model="studentId">
      </div>
      <div class="form-group">
        <label for="id" class="text-cadt text-danger">Date of birth</label>
        <input type="date" required class="form-control" placeholder="" maxlength="10" v-model="studentDob">
      </div>
     
      <div class="form-group">
        <router-link :to="{name:'exit'}" class="btn text-cadt text-left btn-danger float-left"> BACK</router-link>
        <button class="btn text-cadt bg-info float-right" @click="checkData">GO</button>
      </div> 

    </div>
  </form>

  </div>
<div class="bg-info-main" v-if="confirmStudent">
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="card">
        <div class="card-header">
          <div class="profile_pic">
            <img :src="'http://udms.cadt.edu.kh'+student.image_profile">
          </div>
        </div>
        <div class="card-body">
          <div class="d-lfex justify-content-center flex-column">
            <div class="name_container">
              <div class="name">{{student.first_name_en}} {{student.last_name_en}}</div>
            </div>
            <div class="address">{{student.first_name_km}} {{student.last_name_km}}</div>
          </div>
          <div class="follow">
            <div class="follow_btn">G7-Computer Scienc</div>
          </div>
          <div class="info_container">
            <div class="info">
              <p>Date of Birth</p>
              <p>{{student.dob}}</p>								
            </div>
            <div class="info">
              <p>Phone</p>
              <p>0{{student.phone1}}</p>							
            </div>
            <div class="info">
              <p>Email</p>
              <p>{{student.email}}</p>						
            </div>			
          </div>
        </div>
        
        <div class="card-footer">
          <router-link to="/makeRequest/7" class="view_profile"> Update Info</router-link>
          <div class="message" @click="correct">
            Continue
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
</template>

<script> 
import { ref} from 'vue';  
import getStudents from '../composable/getStudent'   
export default {
  name: 'HelloWorld', 
  components: {
   
  },
   setup() {  
    const studentId = ref('');
    const studentDob = ref(''); 
    const confirmStudent = ref('');  
    confirmStudent.value = false;
    const student1 = ref(''); 
    let student = ref(''); 
   const correct = ()=>{
    window.location.href = "/student"
   }
    const submit = async ()=>{ 
      let data= await getStudents(studentId.value);  
      student.value = data.student; 
  
      if (data.student.dob == studentDob.value){
        confirmStudent.value = true;
        localStorage.setItem("tokenStudent", studentId.value);  
      }else{
        alert("Your infomation invalide!")
      }
    } 
    return {student1,confirmStudent,studentId,studentDob,student,submit,correct}; 
  },
  data() {
    return {
      services:[{title:'STUDENT ID', id:1,bgColor:'bg-idt',icon:'fas fa-id-card',route:'student'},
                {title:'TRANSCRIPT', id:2,bgColor:'bg-cadt',icon:'fas fa-table',route:'faculty'},
                {title:'TEMPORY CERTIFICATE', id:2,bgColor:'bg-idt-primary',icon:'fas fa-file-invoice',route:'track'},
                {title:'CERTIFICATE', id:2,bgColor:'bg-idt-primary',icon:'fas fa-id-card-alt',route:'track'},{title:'PERMISSION', id:2,bgColor:'bg-idt-primary',icon:'fas fa-file-signature',route:'track'},{title:'OTHER REQUEST', id:2,bgColor:'bg-idt-primary',icon:'fas fa-folder',route:'track'},
    ]
    }
  },
}
</script>
<style scoped>
body{
  display: grid;
  align-items: center;
  width: 100%;
} 
.main-info{
  position:absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto; 
  top: 50%; 
  width:80%;
  transform: translateY(-50%);
  background-color: rgb(239, 100, 0);
  z-index: 3;
}

.get-student-info{ 
  position:absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto; 
  top: 50%; 
  transform: translateY(-50%);
}
.bg-info-main{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height:100vh;
  z-index: 3;
  background: #444444d9;
}
.card-header{
  background-image: url('http://www.latestseotutorial.com/wp-content/uploads/2016/12/love-dp-for-facebook.jpg') !important;
  padding: 0 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align:center;
}
.card{
  overflow: hidden;
  border:0 !important;
  width: 500px;
  box-shadow: 0px 0px 15px 1px;
  -webkit-box-shadow:0px 0px 15px 1px;
}
.profile_pic{
  position: absolute;
  bottom: -50px;
  height: 112px;
  width: 112px;
  padding: 5px;
  border: 2px solid #f39c12;
  border-radius: 50%;
}
.card-body{
  padding-top: 55px !important;
}
.profile_pic img{ 
  width: 100px;
  border-radius: 50%;
}
.name_container{
  display: flex;
  justify-content: center;

}
.name{
  font-size: 20px;
  font-weight: 700;
  color: gray;
  position: relative;
}
.address{
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: gray;
}
.follow{
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.follow_btn{
  background: #2196F3;
  padding: 7px;
  color: #fff;
  border-radius: 12px;
  cursor: pointer;
}
.info_container{
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.info{
  display: flex;
  flex-direction: column;
}
.info p:first-child{
  margin-bottom: 0;
  font-size: 12px; 
  color: gray;
  text-transform: uppercase;
  text-align: center;
}
.info p:last-child{
  margin-bottom: 0;  
  color: rgb(221, 147, 0); 
  text-align: center;
}
.card-footer{
  padding: 0 !important;
  background: #fff !important;
  display: flex;
  border-top: 0 !important;
}
.message{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50%;
  text-transform: uppercase;
  background: #f39c12;
  color: #fff;
  cursor: pointer;
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.view_profile{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50%;
  text-transform: uppercase;
  background: #e74c3c;
  color: #fff;
  cursor: pointer;
  border-bottom-left-radius: calc(0.25rem - 1px);
  
}
.bg_cover{
  width: 100%; 
  height:100vh;
}
</style>