<template>
  <CFLogin></CFLogin>
</template>

<script>
// @ is an alias to /src
import CFLogin from '@/components/CFLogin.vue'
export default {
  name: 'HomeView',
  components: {
    CFLogin,
  } 
}
</script>
