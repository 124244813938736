<template>  
  <CFService></CFService>
  <div class="bg-info-main" v-if="!confirmstaff">
    <form @submit.prevent="submit">
    <div class="get-staff-info col-md-3 pd-1 bg-idt">
      <h4 class="text-cadt text-white">PLEASE ENTER YOUR INFO</h4>
      <div class="form-group">
        <label for="id" class="text-cadt text-danger">Your Email</label>
        <input type="email" required class="form-control" placeholder="" v-model="staffEmail">
      </div>
      <div class="form-group">
        <label for="id" class="text-danger text-cadt">STAFF ID</label>
        <input type="number" class="form-control" placeholder="" maxlength="10" required v-model="staffId">
      </div>
    
     
      <div class="form-group">
        <router-link :to="{name:'exit'}" class="btn text-cadt text-left btn-danger float-left"> BACK</router-link>
        <button class="btn text-cadt bg-info float-right" @click="checkData">GO</button>
      </div> 

    </div>
  </form>

  </div>
<div class="bg-info-main" v-if="confirmstaff">
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="card">
        <div class="card-header">
          <div class="profile_pic">
            <img src="/assets/img/cadt-4.png">
          </div>
        </div>
        <div class="card-body">
          <div class="follow">
            <div class="follow_btn">{{staff.name_en}} - {{staff.title}}</div>
          </div>
          <div class="info_container">
            <div class="info">
              <p>Institute</p>
              <p>{{staff.institute}}</p>								
            </div>
            <div class="info">
              <p>Phone</p>
              <p>0{{staff.phone}}</p>							
            </div>
            <div class="info">
              <p>Email</p>
              <p>{{staff.email}}</p>						
            </div>			
          </div>
        </div>
        
        <div class="card-footer"> 
          <div class="message" @click="correct">
            Continue
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
</template>

<script> 
import { ref} from 'vue';   
import getStaffs from '../composable/getStaffs'  
import CFService from './ClassFacilitator.vue' 
export default {
  name: 'HelloWorld', 
  components: {
    CFService,
  },
   setup() {  
    const staffId = ref('');
    const staffEmail = ref(''); 
    const confirmstaff = ref('');  
    confirmstaff.value = false;
    const staff1 = ref(''); 
    let staff = ref(''); 
   const correct = ()=>{
    window.location.href = "/class_facilitator"
   }
    const submit = async ()=>{ 
      let data= await getStaffs(staffId.value);  
      staff.value = data.staff;  

      console.log("data.staff.title:",data.staff.title);
      if(data.staff){
      if (data.staff.email == staffEmail.value && data.staff.title.includes('Class Facilitator') ){
        confirmstaff.value = true;
        localStorage.setItem("classFAuth", staffId.value);  
      }else{
        alert("Your infomation invalide!")
      }
    } else{
    alert("Your infomation invalide!")
    }
  }
    return {staff1,confirmstaff,staffId,staffEmail,staff,submit,correct}; 
  }
}
</script>
<style scoped>
body{
  display: grid;
  align-items: center;
  width: 100%;
} 
.main-info{
  position:absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto; 
  top: 50%; 
  width:80%;
  transform: translateY(-50%);
  background-color: rgb(239, 100, 0);
  z-index: 3;
}

.get-staff-info{ 
  position:absolute;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto; 
  top: 50%; 
  transform: translateY(-50%);
}
.bg-info-main{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height:100vh;
  z-index: 3;
  background: #444444d9;
}
.card-header{
  background-image: url('http://www.latestseotutorial.com/wp-content/uploads/2016/12/love-dp-for-facebook.jpg') !important;
  padding: 0 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align:center;
}
.card{
  overflow: hidden;
  border:0 !important;
  width: 500px;
  box-shadow: 0px 0px 15px 1px;
  -webkit-box-shadow:0px 0px 15px 1px;
}
.profile_pic{
  position: absolute;
  height: 150px;
  width: 150px;
  padding: 22px;
  border: 2px solid #f39c12;
  border-radius: 50%;
}
.card-body{
  padding-top: 55px !important;
}
.profile_pic img{
  height: 100px;
  width: 100px;
  border-radius: 0%;
}
.name_container{
  display: flex;
  justify-content: center;

}
.name{
  font-size: 20px;
  font-weight: 700;
  color: gray;
  position: relative;
}
.address{
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: gray;
}
.follow{
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.follow_btn{
  background: #2196F3;
  padding: 7px;
  color: #fff;
  border-radius: 12px;
  cursor: pointer;
}
.info_container{
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.info{
  display: flex;
  flex-direction: column;
}
.info p:first-child{
  margin-bottom: 0;
  font-size: 12px; 
  color: gray;
  text-transform: uppercase;
  text-align: center;
}
.info p:last-child{
  margin-bottom: 0;  
  color: rgb(221, 147, 0); 
  text-align: center;
}
.card-footer{
  padding: 0 !important;
  background: #fff !important;
  display: flex;
  border-top: 0 !important;
  width: 100%;
}
.message{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  text-transform: uppercase;
  background: #f39c12;
  color: #fff;
  cursor: pointer;
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.view_profile{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 50%;
  text-transform: uppercase;
  background: #e74c3c;
  color: #fff;
  cursor: pointer;
  border-bottom-left-radius: calc(0.25rem - 1px);
  
}
</style>