// import { ref } from "vue"; 
import config from '../../config'
const makeRequestPost = async (formData) => {
  let request = {};
  const axios = require("axios");

  try {
    request = await axios.post(config.urlAPI + "/api/make_request", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(res => res.data);

  } catch (err) {
    // error.value = err.message; 
  }
  return { request }
}
export default makeRequestPost