<template>   
  
   
<div class="container1">
  <div data-am-gallery> 
    
    <!-- Radio -->
    <input type="radio" name="gallery" id="img-1" checked />
    <input type="radio" name="gallery" id="img-2" />
    <input type="radio" name="gallery" id="img-3" />
    
    <!-- Images -->
    <div class="images">
      <div class="image" style="background-image: url(https://images.unsplash.com/photo-1433190152045-5a94184895da?crop=entropy&fit=crop&fm=jpg&h=1325&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=2500);"></div>
      <div class="image" style="background-image: url(https://images.unsplash.com/photo-1440557653082-e8e186733eeb?crop=entropy&fit=crop&fm=jpg&h=1325&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=2500);"></div>
      <div class="image" style="background-image: url(https://images.unsplash.com/photo-1449057528837-7ca097b3520c?crop=entropy&fit=crop&fm=jpg&h=1325&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=2500);"></div>
    </div>
    
    <!-- Navigation -->
    <div class="navigation">
      <label class="dot" for="img-1"></label>
      <label class="dot" for="img-2"></label>
      <label class="dot" for="img-3"></label>
    </div>
  </div>
</div> 
</template>

<script> 
import { ref} from 'vue';  
import getUser from '../composable/getUser'   
import getStudent from '@/composable/getStudent'; 
export default {
  name: 'HelloWorld',
   setup() {  
    const studentId = ref('');
    const WelcomeKey = ref(''); 
    const confirmStudent = ref('');  
    confirmStudent.value = false;
    const student1 = ref(''); 
    let student = ref(''); 
    let currentTime = ref('');    
    let check_key = ref('');    
    check_key.value = false;
   
   const correct = ()=>{   
    window.location.href = "/welcome"
   }
   if(localStorage.getItem("WelcomeKey")){
    check_key.value = true;
   }

   const checkKey = ()=>{
    if(WelcomeKey.value == "Cadt@2K2Z!@#"){
      localStorage.setItem("WelcomeKey",true);  
      check_key.value = true; 
    }
    console.log("check_key:",check_key.value)
   }
    const submit = async ()=>{
      let data= await getUser(studentId.value);  
      if(data.result ==null){
        console.log("not found.")
      }else{ 
        if(data.result.user_type==2){
          let studentData= await getStudent(data.result.user_id);  
              student.value = studentData.student; 
              confirmStudent.value = true;
              let d = new Date();
              currentTime.value = d.toLocaleString();
              console.log("DFS:",studentData.student);
              setTimeout(() => {
            confirmStudent.value = false;
            correct();
            }, 1000);
 
        }
      }
    } 
    return {student1,confirmStudent,studentId,WelcomeKey,student,submit,correct,currentTime,checkKey,check_key}; 
  },
  data() {
    return {
      services:[{title:'STUDENT ID', id:1,bgColor:'bg-idt',icon:'fas fa-id-card',route:'student'},
                {title:'TRANSCRIPT', id:2,bgColor:'bg-cadt',icon:'fas fa-table',route:'faculty'},
                {title:'TEMPORY CERTIFICATE', id:2,bgColor:'bg-idt-primary',icon:'fas fa-file-invoice',route:'track'},
                {title:'CERTIFICATE', id:2,bgColor:'bg-idt-primary',icon:'fas fa-id-card-alt',route:'track'},{title:'PERMISSION', id:2,bgColor:'bg-idt-primary',icon:'fas fa-file-signature',route:'track'},{title:'OTHER REQUEST', id:2,bgColor:'bg-idt-primary',icon:'fas fa-folder',route:'track'},
    ]
    }
  },
}
 
</script>
<style scoped> 

[data-am-gallery] {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
 /* Navigation */
 }
 
 [data-am-gallery] input[type="radio"] {
   position: fixed;
   top: -9999px;
 }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .images .image:nth-child(5) { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5) { background-color: #29acbb; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5):hover { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .images .image:nth-child(4) { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4) { background-color: #29acbb; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4):hover { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .images .image:nth-child(3) { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3) { background-color: #29acbb; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3):hover { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .images .image:nth-child(2) { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2) { background-color: #29acbb; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2):hover { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .images .image:nth-child(1) { opacity: 1; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1) { background-color: #29acbb; }
 
 [data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1):hover { opacity: 1; }
 
 [data-am-gallery] .image {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   opacity: 0;
   -webkit-transition: opacity 400ms ease;
   transition: opacity 400ms ease;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
 }
 
 [data-am-gallery] .navigation {
   position: absolute;
   bottom: 15px;
   left: 50%;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
 }
 
 [data-am-gallery] .dot {
   display: inline-block;
   width: 15px;
   height: 15px;
   margin: 0 2px;
   border-radius: 50%;
   background-color: rgba(255, 255, 255, 0.8);
   cursor: pointer;
   -webkit-transition: opacity 200ms ease;
   transition: opacity 200ms ease;
 }
 
 [data-am-gallery] .dot:hover { opacity: 0.8; }
 
 /* Base */
 
 body { margin: 0; }
 
 .container1 {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
 }
</style>